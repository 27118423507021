import React, { Component } from "react";

import { InputSwitch } from "primereact/inputswitch";

import ControlService from "../services/ControlService";
import * as Constants from "../constants/constants";

export default class AppRemoteControl extends Component {
	// device_imei: "B8:F0:09:B5:5E:40"
	// device_name: "Hello, IoT!"
	// device_status: "online"
	// msw1: 1
	// msw1_changed_by: "Bung Bu"
	// msw1_name: "Water Pump"
	// msw2: 0
	// msw2_changed_by: "Bung Bu"
	// msw2_name: "Garage Light"
	// nwk_ap: "GAAC"
	// nwk_ip: "192.168.215.114"
	// nwk_rssi: -58
	// time: "2020-12-31T17:35:31.059Z"
	initState = {
		msw1: false,
		msw1_name: "...",
		msw1_changed_by: "...",
		msw1_state_message: "Đang ...",
		msw2: false,
		msw2_name: "...",
		msw2_changed_by: "...",
		msw2_state_message: "Đang ...",
	};

	constructor(props) {
		super(props);

		this.state = this.initState;

		this.controlService = new ControlService();
		this.onWebSocketMessage = this.onWebSocketMessage.bind(this);
		this.msw1Handler = this.msw1Handler.bind(this);
		this.msw2Handler = this.msw2Handler.bind(this);
	}

	componentDidMount() {
		this.controlService.wsInit(this.onWebSocketMessage);
	}

	componentWillUnmount() {
		this.controlService.wsDeinit();
	}

	onWebSocketMessage(message) {
		if (message.error === 1) {
			localStorage.clear();
			window.location.href = "/login";
		}

		// console.log(message);

		if (message.type === "update_all")
		{
			if (message.list_mswitch.length !== 0)
			{
				let mswitch = message.list_mswitch.find(obj => obj.device_imei === Constants.IMEI_HELLO);

				if (mswitch !== undefined)
				{
					this.setState({ 
						msw1: mswitch.msw1 === 1 ? true : false,
						msw1_changed_by: mswitch.msw1_changed_by,
						msw1_state_message: mswitch.msw1 === 1 ? "Đang BẬT" : "Đang TẮT",
						msw2: mswitch.msw2 === 1 ? true : false,
						msw2_changed_by: mswitch.msw2_changed_by,
						msw2_state_message: mswitch.msw2 === 1 ? "Đang BẬT" : "Đang TẮT",
					});
				}
			}
		}
		else if (message.type === "state_changed")
		{
			if (message.data.device_imei === Constants.IMEI_HELLO)
			{
				this.setState({ 
					msw1: message.data.msw1 === 1 ? true : false,
					msw1_changed_by: message.data.msw1_changed_by,
					msw1_state_message: message.data.msw1 === 1 ? "Đang BẬT" : "Đang TẮT",
					msw2: message.data.msw2 === 1 ? true : false,
					msw2_changed_by: message.data.msw2_changed_by,
					msw2_state_message: message.data.msw2 === 1 ? "Đang BẬT" : "Đang TẮT",
				});	
			}
		}
	}

	msw1Handler(e) {
		this.setState({ msw1: e.value });
		let message = {
			imei: Constants.IMEI_HELLO,
			type: "m-switch",
			cmd: {
				msw1: e.value === true ? 1 : 0,
			},
			author: localStorage.getItem(Constants.STORAGE_USER_AKA),
			token: localStorage.getItem(Constants.STORAGE_JWT),
		};
		this.controlService.wsSend(JSON.stringify(message));
	}

	msw2Handler(e) {
		this.setState({ msw2: e.value });
		let message = {
			imei: Constants.IMEI_HELLO,
			type: "m-switch",
			cmd: {
				msw2: e.value === true ? 1 : 0,
			},
			author: localStorage.getItem(Constants.STORAGE_USER_AKA),
			token: localStorage.getItem(Constants.STORAGE_JWT),
		};
		this.controlService.wsSend(JSON.stringify(message));
	}

	render() {
		return (
			<div className="p-grid">
				<div className="p-col-12 p-md-6 p-lg-3">
					<div
						className="card"
						style={{ position: "relative", textAlign: "left", height: "100px" }}
					>
						<div
							style={{
								position: "absolute",
								top: "8px",
								left: "16px",
								fontSize: "18px",
								color: "#5e81ac",
								fontWeight: "bold",
							}}
						>
							Bơm Nước
						</div>
						<div
							style={{
								position: "absolute",
								top: "8px",
								right: "16px",
								fontSize: "18px",
							}}
						>
							online
						</div>

						<div
							style={{
								position: "absolute",
								top: "40px",
								left: "16px",
								fontSize: "16px",
								fontWeight: "bold",
							}}
						>
							{this.state.msw1_state_message}
						</div>

						<div
							style={{
								position: "absolute",
								top: "40px",
								right: "16px",
								fontSize: "18px",
							}}
						>
							<InputSwitch
								checked={this.state.msw1}
								onChange={(e) => this.msw1Handler(e)}
							/>
						</div>

						<div
							style={{
								position: "absolute",
								bottom: "8px",
								left: "16px",
								fontSize: "14px",
							}}
						>
							{`Điều khiển lần cuối bởi: ${this.state.msw1_changed_by}.`}
						</div>
						{/* <div
							style={{
								position: "absolute",
								bottom: "8px",
								right: "16px",
								fontSize: "18px",
							}}
						>
							Bottom Right
						</div> */}
					</div>
				</div>

				<div className="p-col-12 p-md-6 p-lg-3">
					<div
						className="card"
						style={{ position: "relative", textAlign: "left", height: "100px" }}
					>
						<div
							style={{
								position: "absolute",
								top: "8px",
								left: "16px",
								fontSize: "18px",
								color: "#5e81ac",
								fontWeight: "bold",
							}}
						>
							Đèn Gara
						</div>
						<div
							style={{
								position: "absolute",
								top: "8px",
								right: "16px",
								fontSize: "18px",
							}}
						>
							online
						</div>

						<div
							style={{
								position: "absolute",
								top: "40px",
								left: "16px",
								fontSize: "16px",
								fontWeight: "bold",
							}}
						>
							{this.state.msw2_state_message}
						</div>

						<div
							style={{
								position: "absolute",
								top: "40px",
								right: "16px",
								fontSize: "18px",
							}}
						>
							<InputSwitch
								checked={this.state.msw2}
								onChange={(e) => this.msw2Handler(e)}
							/>
						</div>

						<div
							style={{
								position: "absolute",
								bottom: "8px",
								left: "16px",
								fontSize: "14px",
							}}
						>
							{`Điều khiển lần cuối bởi: ${this.state.msw2_changed_by}.`}
						</div>
						{/* <div
							style={{
								position: "absolute",
								bottom: "8px",
								right: "16px",
								fontSize: "18px",
							}}
						>
							Bottom Right
						</div> */}
					</div>
				</div>
			</div>
		);
	}
}
