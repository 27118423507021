import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import AppDashboard from "../components/Dashboard";
import AppRemoteControl from "../components/Control";
import AppActivityRecord from "../components/ActivityRecord";
import AppDeviceTable from "../components/DeviceTable";
import AppUserTable from "../components/UserTable";
import AppLog from "../components/Log";

export default class AppRouter extends Component {
	render() {
		return (
			<Switch>
				<Route exact path="/">
					<Redirect to="/dashboard" />
				</Route>

				<Route exact path="/dashboard" component={AppDashboard} />

				<Route exact path="/control" component={AppRemoteControl} />

				<Route exact path="/activities" component={AppActivityRecord} />

				<Route exact path="/devices" component={AppDeviceTable} />

				<Route exact path="/users" component={AppUserTable} />

				<Route exact path="/logs" component={AppLog} />

				<Route exact path="*">
					<Redirect to="/404" />
				</Route>
			</Switch>
		);
	}
}
