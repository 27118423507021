import * as Constants from "../constants/constants";

export default class ControlService {
	jwt = localStorage.getItem(Constants.STORAGE_JWT);
	timerID = undefined;

	ws = null;
	wsUri = "";
	onMessageCallback = null;

	wsInit(onMsgEvt) {
		this.wsUri = Constants.SERVER_WS_URL + "/hiot";
		this.onMessageCallback = onMsgEvt;

		this.ws = new WebSocket(this.wsUri);

		this.ws.onopen = () => {
			console.log("connected");
			let msg = {
				type: "auth",
				token: this.jwt,
			};
			this.ws.send(JSON.stringify(msg));

			this.timerID = setInterval(() => this.wsPing(), 45000);
		};

		this.ws.onmessage = (evt) => {
			const message = JSON.parse(evt.data);
			if (this.onMessageCallback)
			{
				this.onMessageCallback(message);
			}
		};

		this.ws.onclose = () => {
			console.log("disconnected");
			if (this.ws !== null)
			{
				setTimeout(this.wsInit.bind(this), 1000, this.onMessageCallback.bind(this));
				console.log("Reinit...");	
			}
		};

		this.ws.onerror = (err) => {
			console.error(
				"Socket encountered error: ",
				err.message,
				"Closing socket..."
			);

			this.ws.close();
		};
	}

	wsDeinit() {
		this.ws.close();
		this.ws = null;
		this.onMessageCallback = null;

		clearInterval(this.timerID);
	}

	wsSend(jsonString) {
		try {
			this.ws.send(jsonString);
		} catch (error) {
			console.log(error);
		}
	}

	wsPing() {
		let msg = {
			type: "ping",
		};
		this.ws.send(JSON.stringify(msg));
	}
}
