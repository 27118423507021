import React, { Component } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { Toolbar } from "primereact/toolbar";
import { TabView, TabPanel } from "primereact/tabview";

import DeviceService from "../services/DeviceService";

export default class AppDeviceTable extends Component {
	emptyDevice = {
		time: null,
		device_imei: "",
		device_name: "",
		device_status: "",
		msw1_name: "",
		msw2_name: "",
		nwk_ap: "",
		nwk_ip: "",
		nwk_rssi: 0,
		last_modified: "",
	};

	constructor(props) {
		super(props);

		this.state = {
			device: { ...this.emptyDevice },
			deviceList: [],
			selectedList: [],
			flag_DialogDelDevice: false,
			flag_DialogAddDevice: false,
			flag_DialogEditDevice: false,
			submitted: false,
			globalFilter: null,
		};

		this.deviceService = new DeviceService();
		this.exportCSV = this.exportCSV.bind(this);

		this.updateDeviceList = this.updateDeviceList.bind(this);

		this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

		this.timeBodyTemplate = this.timeBodyTemplate.bind(this);
		this.lastModBodyTemplate = this.lastModBodyTemplate.bind(this);
		this.imeiDeviceBodyTemplate = this.imeiDeviceBodyTemplate.bind(this);
		this.nameDeviceBodyTemplate = this.nameDeviceBodyTemplate.bind(this);
		this.statusDeviceBodyTemplate = this.statusDeviceBodyTemplate.bind(this);
		this.nameMSW1BodyTemplate = this.nameMSW1BodyTemplate.bind(this);
		this.nameMSW2BodyTemplate = this.nameMSW2BodyTemplate.bind(this);

		this.showDialogDelDevice = this.showDialogDelDevice.bind(this);
		this.hideDialogDelDevice = this.hideDialogDelDevice.bind(this);
		this.handlerDelDevice = this.handlerDelDevice.bind(this);

		this.showDialogEditDevice = this.showDialogEditDevice.bind(this);
		this.hideDialogEditDevice = this.hideDialogEditDevice.bind(this);
		this.handlerEditDevice = this.handlerEditDevice.bind(this);

		this.showDialogAddDevice = this.showDialogAddDevice.bind(this);
		this.hideDialogAddDevice = this.hideDialogAddDevice.bind(this);
		this.onInputChange = this.onInputChange.bind(this);
		this.onCategoryChange = this.onCategoryChange.bind(this);
		this.handlerAddDevice = this.handlerAddDevice.bind(this);
	}

	componentDidMount() {
		this.updateDeviceList();
		// this.testService("TOOL_SENTINEL");
	}

	async updateDeviceList() {
		try {
			let resp = await this.deviceService.list();
			let map_list = resp.data.map((element) => {
				let map_obj = {};
				let now_gmt7 = new Date(Date.parse(element.time) + 3600 * 7 * 1000);
				map_obj.time = now_gmt7.toISOString();
				map_obj.device_imei = element.device_imei;
				map_obj.device_name = element.device_name;
				map_obj.device_status = element.device_status;
				map_obj.msw1_name = element.msw1_name;
				map_obj.msw2_name = element.msw2_name;
				map_obj.nwk_ap = element.nwk_ap;
				map_obj.nwk_ip = element.nwk_ip;
				map_obj.nwk_rssi = element.nwk_rssi;
				now_gmt7 = new Date(element.last_modified + 3600 * 7 * 1000);
				map_obj.last_modified = now_gmt7.toISOString().slice(0, 19);
				return map_obj;
			});
			this.setState({ deviceList: map_list });
			// console.log(map_list);
		} catch (error) {
			console.log(error.message);
		}
	}

	exportCSV() {
		this.dt.exportCSV();
	}

	showDialogAddDevice() {
		this.setState({
			device: { ...this.emptyDevice },
			submitted: false,
			flag_DialogAddDevice: true,
		});
	}

	hideDialogAddDevice() {
		this.setState({
			submitted: false,
			flag_DialogAddDevice: false,
		});
	}

	onInputChange(e, name) {
		const val = (e.target && e.target.value) || "";
		let device = { ...this.state.device };
		device[`${name}`] = val;
		this.setState({ device });
	}

	onCategoryChange(e) {
		let device = { ...this.state.device };
		device["status"] = e.value;
		this.setState({ device });
	}

	handlerAddDevice() {
		if (
			this.state.device.uuid.length &&
			this.state.device.owner.length &&
			this.state.device.status.length
		) {
			let now = new Date();
			let now_gmt7 = new Date(now.getTime() + 3600 * 7 * 1000);

			let device = { ...this.state.device };
			device.time = now_gmt7.toISOString();

			this.setState({
				device: { ...this.emptyDevice },
				deviceList: [device, ...this.state.deviceList],
				flag_DialogAddDevice: false,
				submitted: false,
			});

			this.toast.show({
				severity: "success",
				summary: "Successful",
				detail: "Device added",
				life: 3000,
			});
		}

		this.setState({ submitted: true });
	}

	showDialogDelDevice(rowData) {
		this.setState({
			device: rowData,
			flag_DialogDelDevice: true,
		});
	}

	hideDialogDelDevice() {
		this.setState({ flag_DialogDelDevice: false });
	}

	handlerDelDevice() {
		let deviceList = this.state.deviceList.filter(
			(val) => val.uuid !== this.state.device.uuid
		);
		this.toast.show({
			severity: "success",
			summary: "Successful",
			detail: "Device Deleted",
			life: 3000,
		});
		this.setState({
			flag_DialogDelDevice: false,
			device: { ...this.emptyDevice },
			deviceList: deviceList,
		});
	}

	showDialogEditDevice(rowData) {
		this.setState({
			device: rowData,
			flag_DialogEditDevice: true,
		});
	}

	hideDialogEditDevice() {
		this.setState({
			submitted: false,
			flag_DialogEditDevice: false,
		});
	}

	handlerEditDevice(device) {
		if (
			this.state.device.uuid.length &&
			this.state.device.status.length &&
			this.state.device.owner.length &&
			this.state.device.manager.length
		) {
			let index = this.state.deviceList.findIndex(
				(element) => element.uuid === this.state.device.uuid
			);
			let deviceList = [...this.state.deviceList];
			deviceList[index].status = this.state.device.status;
			deviceList[index].owner = this.state.device.owner;
			deviceList[index].manager = this.state.device.manager;

			this.setState({
				device: { ...this.emptyDevice },
				deviceList: deviceList,
				flag_DialogEditDevice: false,
				submitted: false,
			});

			this.toast.show({
				severity: "success",
				summary: "Successful",
				detail: "Device edited",
				life: 3000,
			});
		}

		this.setState({ submitted: true });
	}

	actionBodyTemplate(rowData) {
		return (
			<div align="center">
				<Button
					icon="pi pi-pencil"
					className="p-button-rounded p-button-secondary p-button-outlined p-mr-2"
					onClick={() => this.showDialogEditDevice(rowData)}
				/>
				<Button
					icon="pi pi-trash"
					className="p-button-rounded p-button-secondary p-button-outlined"
					onClick={() => this.showDialogDelDevice(rowData)}
				/>
			</div>
		);
	}

	timeBodyTemplate(rowData) {
		return (
			<React.Fragment>
				<span className="p-column-title">Created At</span>
				{rowData.time}
			</React.Fragment>
		);
	}

	lastModBodyTemplate(rowData) {
		return (
			<React.Fragment>
				<span className="p-column-title">Last modified</span>
				<i>{rowData.last_modified}</i>
			</React.Fragment>
		);
	}
	n;

	imeiDeviceBodyTemplate(rowData) {
		return (
			<React.Fragment>
				<span className="p-column-title">IMEI</span>
				{rowData.device_imei}
			</React.Fragment>
		);
	}

	nameDeviceBodyTemplate(rowData) {
		return (
			<React.Fragment>
				<span className="p-column-title">Name</span>
				{rowData.device_name}
			</React.Fragment>
		);
	}

	statusDeviceBodyTemplate(rowData) {
		let labelTable = {
			online: "highlight-green",
			offline: "highlight-orange",
		};
		return (
			<React.Fragment>
				<span className="p-column-title">Status</span>
				{
					<span className={`hl-label ${labelTable[rowData.device_status]}`}>
						{rowData.device_status}
					</span>
				}
			</React.Fragment>
		);
	}

	nameMSW1BodyTemplate(rowData) {
		return (
			<React.Fragment>
				<span className="p-column-title">msw1Name</span>
				{rowData.msw1_name}
			</React.Fragment>
		);
	}

	nameMSW2BodyTemplate(rowData) {
		return (
			<React.Fragment>
				<span className="p-column-title">msw2Name</span>
				{rowData.msw2_name}
			</React.Fragment>
		);
	}

	render() {
		const header = (
			<div className="table-header">
				<span></span>
				<span className="p-input-icon-left">
					<i className="pi pi-search" />
					<InputText
						type="search"
						onInput={(e) => this.setState({ globalFilter: e.target.value })}
						placeholder="Search..."
					/>
				</span>
			</div>
		);

		const paginatorLeft = (
			<Button type="button" icon="pi pi-refresh" className="p-button-text" />
		);
		const paginatorRight = (
			<Button type="button" icon="pi pi-cloud" className="p-button-text" />
		);

		const footerDialogDelete = (
			<React.Fragment>
				<Button
					label="No"
					icon="pi pi-times"
					className="p-button-text"
					onClick={this.hideDialogDelDevice}
				/>
				<Button
					label="Yes"
					icon="pi pi-check"
					className="p-button-text"
					onClick={this.handlerDelDevice}
				/>
			</React.Fragment>
		);

		const footerDialogAdd = (
			<React.Fragment>
				<Button
					label="Cancel"
					icon="pi pi-times"
					className="p-button-text"
					onClick={this.hideDialogAddDevice}
				/>
				<Button
					label="Add"
					icon="pi pi-check"
					className="p-button-text"
					onClick={this.handlerAddDevice}
				/>
			</React.Fragment>
		);

		const footerDialogEdit = (
			<React.Fragment>
				<Button
					label="Cancel"
					icon="pi pi-times"
					className="p-button-text"
					onClick={this.hideDialogEditDevice}
				/>
				<Button
					label="Save"
					icon="pi pi-check"
					className="p-button-text"
					onClick={this.handlerEditDevice}
				/>
			</React.Fragment>
		);

		const leftContents = <React.Fragment></React.Fragment>;

		const rightContents = (
			<span className="p-buttonset">
				<Button
					label="Export to CSV"
					className="p-button-raised p-button-success"
					icon="pi pi-cloud-download"
					onClick={this.exportCSV}
				/>
				<Button
					label="Add"
					className="p-button-raised p-button-info"
					icon="pi pi-plus-circle "
					onClick={this.showDialogAddDevice}
				/>
			</span>
		);

		return (
			<div className="datatable-crud-demo datatable-responsive-demo">
				<Toast ref={(el) => (this.toast = el)} />
				<TabView>
					<TabPanel header="M-Sw">
						<Dialog
							visible={this.state.flag_DialogAddDevice}
							style={{ width: "450px" }}
							header="Device Details"
							modal
							className="p-fluid"
							footer={footerDialogAdd}
							onHide={this.hideDialogAddDevice}
						>
							<div className="p-field">
								<label htmlFor="uuid">IMEI</label>
								<InputText
									id="uuid"
									value={this.state.device.uuid}
									onChange={(e) => this.onInputChange(e, "uuid")}
									required
									autoFocus
									className={`${
										this.state.submitted && !this.state.device.uuid
											? "p-invalid"
											: ""
									}`}
								/>
								{this.state.submitted && !this.state.device.uuid && (
									<small className="p-invalid">IMEI is required.</small>
								)}
							</div>

							<div className="p-field">
								<label htmlFor="owner">Owner</label>
								<InputText
									id="owner"
									value={this.state.device.owner}
									onChange={(e) => this.onInputChange(e, "owner")}
									required
									autoFocus
									className={`${
										this.state.submitted && !this.state.device.owner
											? "p-invalid"
											: ""
									}`}
								/>
								{this.state.submitted && !this.state.device.owner && (
									<small className="p-invalid">Owner is required.</small>
								)}
							</div>

							<div className="p-field">
								<label className="p-mb-3">Status</label>
								<div className="p-formgrid p-grid">
									<div className="p-field-radiobutton p-col-6">
										<RadioButton
											id="category1"
											name="category"
											value="ACTIVE"
											onChange={this.onCategoryChange}
											checked={this.state.device.status === "ACTIVE"}
										/>
										<label
											htmlFor="category1"
											className="hl-label highlight-green"
										>
											ACTIVE
										</label>
									</div>
									<div className="p-field-radiobutton p-col-6">
										<RadioButton
											id="category2"
											name="category"
											value="INACTIVE"
											onChange={this.onCategoryChange}
											checked={this.state.device.status === "INACTIVE"}
										/>
										<label
											htmlFor="category2"
											className="hl-label highlight-orange"
										>
											INACTIVE
										</label>
									</div>
									<div className="p-field-radiobutton p-col-6">
										{this.state.submitted && !this.state.device.status && (
											<small className="p-invalid">Status is required.</small>
										)}
									</div>
								</div>
							</div>
						</Dialog>

						<Dialog
							visible={this.state.flag_DialogEditDevice}
							style={{ width: "450px" }}
							header="Device Details"
							modal
							className="p-fluid"
							footer={footerDialogEdit}
							onHide={this.hideDialogEditDevice}
						>
							<div className="p-field">
								<label htmlFor="uuid">IMEI</label>
								<InputText id="uuid" value={this.state.device.uuid} disabled />
							</div>
							<div className="p-field">
								<label htmlFor="name">Owner</label>
								<InputText
									id="owner"
									value={this.state.device.owner}
									onChange={(e) => this.onInputChange(e, "owner")}
									required
									autoFocus
									className={`${
										this.state.submitted && !this.state.device.owner
											? "p-invalid"
											: ""
									}`}
								/>
								{this.state.submitted && !this.state.device.owner && (
									<small className="p-invalid">Name is required.</small>
								)}
							</div>

							<div className="p-field">
								<label htmlFor="manager">Manager</label>
								<InputText
									id="manager"
									value={this.state.device.manager}
									onChange={(e) => this.onInputChange(e, "manager")}
									required
									autoFocus
									className={`${
										this.state.submitted && !this.state.device.manager
											? "p-invalid"
											: ""
									}`}
								/>
								{this.state.submitted && !this.state.device.manager && (
									<small className="p-invalid">Location is required.</small>
								)}
							</div>

							<div className="p-field">
								<label className="p-mb-3">Status</label>
								<div className="p-formgrid p-grid">
									<div className="p-field-radiobutton p-col-6">
										<RadioButton
											id="category1"
											name="category"
											value="ACTIVE"
											onChange={this.onCategoryChange}
											checked={this.state.device.status === "ACTIVE"}
										/>
										<label
											htmlFor="category1"
											className="hl-label highlight-green"
										>
											ACTIVE
										</label>
									</div>
									<div className="p-field-radiobutton p-col-6">
										<RadioButton
											id="category2"
											name="category"
											value="INACTIVE"
											onChange={this.onCategoryChange}
											checked={this.state.device.status === "INACTIVE"}
										/>
										<label
											htmlFor="category2"
											className="hl-label highlight-orange"
										>
											INACTIVE
										</label>
									</div>
									<div className="p-field-radiobutton p-col-6">
										{this.state.submitted && !this.state.device.status && (
											<small className="p-invalid">Status is required.</small>
										)}
									</div>
								</div>
							</div>
						</Dialog>

						<Toolbar left={leftContents} right={rightContents} />

						<DataTable
							value={this.state.deviceList}
							ref={(el) => {
								this.dt = el;
							}}
							selection={this.state.selectedList}
							onSelectionChange={(e) =>
								this.setState({ selectedList: e.value })
							}
							// selectionMode="multiple"
							metaKeySelection={false}
							style={{ marginTop: "1em" }}
							className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive-demo"
							paginator
							paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
							currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
							rows={10}
							rowsPerPageOptions={[10, 20, 50, 100]}
							paginatorLeft={paginatorLeft}
							paginatorRight={paginatorRight}
							globalFilter={this.state.globalFilter}
							header={header}
						>
							<Column
								field="time"
								header="Created At"
								body={this.timeBodyTemplate}
								sortable
							></Column>
							<Column
								field="device_imei"
								header="IMEI"
								body={this.imeiDeviceBodyTemplate}
								sortable
							></Column>
							<Column
								field="device_name"
								header="Name"
								body={this.nameDeviceBodyTemplate}
								sortable
							></Column>
							<Column
								field="device_status"
								header="Status"
								body={this.statusDeviceBodyTemplate}
								sortable
							></Column>
							<Column
								field="msw1_name"
								header="msw1Name"
								body={this.nameMSW1BodyTemplate}
								sortable
							></Column>
							<Column
								field="msw2_name"
								header="msw2Name"
								body={this.nameMSW2BodyTemplate}
								sortable
							></Column>
							<Column
								header={<div align="center">Actions</div>}
								body={this.actionBodyTemplate}
							></Column>
							<Column
								field="last_modified"
								header="Last modified"
								body={this.lastModBodyTemplate}
								sortable
							></Column>
						</DataTable>

						<Dialog
							visible={this.state.flag_DialogDelDevice}
							style={{ width: "450px" }}
							header="Confirm"
							modal
							footer={footerDialogDelete}
							onHide={this.hideDialogDelDevice}
						>
							<div className="confirmation-content">
								<i
									className="pi pi-exclamation-triangle p-mr-3"
									style={{ fontSize: "2rem" }}
								/>
								{this.state.device && (
									<span>
										Are you sure you want to delete{" "}
										<b>{this.state.device.owner}</b>?
									</span>
								)}
							</div>
						</Dialog>
					</TabPanel>
					<TabPanel header="E-Sw">Updating...</TabPanel>
					<TabPanel header="M-Sensor">Updating...</TabPanel>
					<TabPanel header="E-Sensor">Updating...</TabPanel>
				</TabView>
			</div>
		);
	}
}
