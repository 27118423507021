import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

export default class AppSidebar extends Component {
	defaultMenu = {
		selectedDashboard: false,
		selectedControl: false,
		selectedStation: false,
		selectedDevice: false,
		selectedUser: false,
		selectedLog: false,
	};

	constructor(props) {
		super(props);

		this.state = {
			menu: this.defaultMenu,
		};

		this.onSelect = this.onSelect.bind(this);
	}

	componentDidMount() {
		let initMenuState = { menu: { ...this.defaultMenu } };
		initMenuState.menu.selectedDashboard = true;
		this.onSelect(window.location.pathname);
	}

	onSelect(tab) {
		let newMenuState = { menu: { ...this.defaultMenu } };

		if (tab === "/" || tab === "/dashboard") {
			newMenuState.menu.selectedDashboard = true;
		} else if (tab === "/control") {
			newMenuState.menu.selectedControl = true;
		} else if (tab === "/activities") {
			newMenuState.menu.selectedStation = true;
		} else if (tab === "/devices") {
			newMenuState.menu.selectedDevice = true;
		} else if (tab === "/users") {
			newMenuState.menu.selectedUser = true;
		} else if (tab === "/logs") {
			newMenuState.menu.selectedLog = true;
		}

		this.setState(newMenuState);
		this.props.mobileHandler();
	}

	render() {
		return (
			<React.Fragment>
				<div className="sidebar-logo">
					<Link
						to="/"
						className="logo"
						aria-label="App logo"
						onClick={() => this.onSelect("/")}
					>
						<img
							alt="logo"
							src={require("../assets/images/logo.png").default}
						/>
						<span className="app-name">Hello, IoT</span>
					</Link>
				</div>
				<div className="layout-menu-container">
					<ul className="layout-menu">
						<li
							className={
								this.state.menu.selectedDashboard ? "active-menuitem" : ""
							}
							data-tip
							data-for="dashboard"
						>
							<Link to="/" onClick={() => this.onSelect("/")}>
								<span className="menuitem-text">Dashboard</span>
								<i className="pi pi-fw pi-home"></i>
							</Link>
							<ReactTooltip id="dashboard" type="dark" effect="solid">
								<span>Dashboard</span>
							</ReactTooltip>
						</li>
						<li
							className={
								this.state.menu.selectedControl ? "active-menuitem" : ""
							}
							data-tip
							data-for="control"
						>
							<Link to="/control" onClick={() => this.onSelect("/control")}>
								<span className="menuitem-text">Control</span>
								<i className="pi pi-fw pi-power-off"></i>
							</Link>
							<ReactTooltip id="control" type="dark" effect="solid">
								<span>Control</span>
							</ReactTooltip>
						</li>
						<li
							className={
								this.state.menu.selectedStation ? "active-menuitem" : ""
							}
							data-tip
							data-for="activities"
						>
							<Link
								to="/activities"
								onClick={() => this.onSelect("/activities")}
							>
								<span className="menuitem-text">Activities</span>
								<i className="pi pi-fw pi-list"></i>
							</Link>
							<ReactTooltip id="activities" type="dark" effect="solid">
								<span>Activities</span>
							</ReactTooltip>
						</li>
						<li
							className={
								this.state.menu.selectedDevice ? "active-menuitem" : ""
							}
							data-tip
							data-for="devices"
						>
							<Link to="/devices" onClick={() => this.onSelect("/devices")}>
								<span className="menuitem-text">Devices</span>
								<i className="pi pi-fw pi-id-card"></i>
							</Link>
							<ReactTooltip id="devices" type="dark" effect="solid">
								<span>Devices</span>
							</ReactTooltip>
						</li>
						<li
							className={this.state.menu.selectedUser ? "active-menuitem" : ""}
							data-tip
							data-for="users"
						>
							<Link to="/users" onClick={() => this.onSelect("/users")}>
								<span className="menuitem-text">Users</span>
								<i className="pi pi-fw pi-users"></i>
							</Link>
							<ReactTooltip id="users" type="dark" effect="solid">
								<span>Users</span>
							</ReactTooltip>
						</li>
						<li
							className={this.state.menu.selectedLog ? "active-menuitem" : ""}
							data-tip
							data-for="logs"
						>
							<Link to="/logs" onClick={() => this.onSelect("/logs")}>
								<span className="menuitem-text">Logs</span>
								<i className="pi pi-fw pi-eye"></i>
							</Link>
							<ReactTooltip id="logs" type="dark" effect="solid">
								<span>Logs</span>
							</ReactTooltip>
						</li>
					</ul>
				</div>
			</React.Fragment>
		);
	}
}
