import axios from "axios";

import * as Constants from "../constants/constants";

export default class UserService {
	jwt = localStorage.getItem(Constants.STORAGE_JWT);

	list() {
		return axios({
			method: "get",
			baseURL: Constants.SERVER_HTTP_URL,
			url: "/hiot/user/list",
			timeout: 4000,
			headers: {
				Authorization: "Bearer " + this.jwt,
			},
		});
	}

	login(username, password, secret) {
		return axios({
			method: "post",
			baseURL: Constants.SERVER_HTTP_URL,
			url: "/hiot/user/login",
			timeout: 4000,
			data: { username: username, password: password, secret: secret },
			headers: {
				Authorization: "Bearer " + this.jwt,
			},
		});
	}
}
