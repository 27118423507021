import React, { Component } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";

import UserService from "../services/UserService";

export default class AppUserTable extends Component {
	emptyUser = {
		time: null,
		username: "",
		aka: "",
		role: "",
		password: "",
	};

	constructor(props) {
		super(props);

		this.state = {
			user: { ...this.emptyUser },
			userList: [],
			selectedList: [],
			flag_DialogDelUser: false,
			flag_DialogAddUser: false,
			flag_DialogEditUser: false,
			submitted: false,
			globalFilter: null,
		};

		this.userService = new UserService();
		this.exportCSV = this.exportCSV.bind(this);

		this.testService = this.testService.bind(this);
		this.updateUserList = this.updateUserList.bind(this);

		this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

		this.timeBodyTemplate = this.timeBodyTemplate.bind(this);
		this.usernameBodyTemplate = this.usernameBodyTemplate.bind(this);
		this.akaBodyTemplate = this.akaBodyTemplate.bind(this);
		this.roleBodyTemplate = this.roleBodyTemplate.bind(this);

		this.showDialogDelUser = this.showDialogDelUser.bind(this);
		this.hideDialogDelUser = this.hideDialogDelUser.bind(this);
		this.handlerDelUser = this.handlerDelUser.bind(this);

		this.showDialogEditUser = this.showDialogEditUser.bind(this);
		this.hideDialogEditUser = this.hideDialogEditUser.bind(this);
		this.handlerEditUser = this.handlerEditUser.bind(this);

		this.showDialogAddUser = this.showDialogAddUser.bind(this);
		this.hideDialogAddUser = this.hideDialogAddUser.bind(this);
		this.onInputChange = this.onInputChange.bind(this);
		this.onCategoryChange = this.onCategoryChange.bind(this);
		this.handlerAddUser = this.handlerAddUser.bind(this);
	}

	componentDidMount() {
		this.updateUserList();
		// this.testService("TOOL_SENTINEL");
	}

	async testService(imei) {
		try {
			let res = await this.userService.list();
			console.log(res);
			let resp = await this.userService.backdoor(imei);
			console.log(resp);
		} catch (error) {
			console.log(error.message);
		}
	}

	async updateUserList() {
		try {
			let resp = await this.userService.list();
			let map_list = resp.data.map((element) => {
				let map_obj = {};
				let now_gmt7 = new Date(Date.parse(element.time) + 3600 * 7 * 1000);
				map_obj.time = now_gmt7.toISOString();
				map_obj.username = element.username;
				map_obj.aka = element.aka;
				map_obj.role = element.role;
				map_obj.password = "";
				return map_obj;
			});
			this.setState({ userList: map_list });
			// console.log(map_list);
		} catch (error) {
			console.log(error.message);
		}
	}

	exportCSV() {
		this.dt.exportCSV();
	}

	showDialogAddUser() {
		this.setState({
			user: { ...this.emptyUser },
			submitted: false,
			flag_DialogAddUser: true,
		});
	}

	hideDialogAddUser() {
		this.setState({
			submitted: false,
			flag_DialogAddUser: false,
		});
	}

	onInputChange(e, name) {
		const val = (e.target && e.target.value) || "";
		let user = { ...this.state.user };
		user[`${name}`] = val;
		this.setState({ user });
	}

	onCategoryChange(e) {
		let user = { ...this.state.user };
		user["status"] = e.value;
		this.setState({ user });
	}

	handlerAddUser() {
		if (
			this.state.user.username.length &&
			this.state.user.aka.length &&
			this.state.user.password.length
		) {
			let now = new Date();
			let now_gmt7 = new Date(now.getTime() + 3600 * 7 * 1000);

			let user = { ...this.state.user };
			user.role = "user";
			user.time = now_gmt7.toISOString();

			this.setState({
				user: { ...this.emptyUser },
				userList: [user, ...this.state.userList],
				flag_DialogAddUser: false,
				submitted: false,
			});

			this.toast.show({
				severity: "success",
				summary: "Successful",
				detail: "User added",
				life: 3000,
			});
		}

		this.setState({ submitted: true });
	}

	showDialogDelUser(rowData) {
		this.setState({
			user: rowData,
			flag_DialogDelUser: true,
		});
	}

	hideDialogDelUser() {
		this.setState({ flag_DialogDelUser: false });
	}

	handlerDelUser() {
		let userList = this.state.userList.filter(
			(val) => val.username !== this.state.user.username
		);
		this.toast.show({
			severity: "success",
			summary: "Successful",
			detail: "User Deleted",
			life: 3000,
		});
		this.setState({
			flag_DialogDelUser: false,
			user: { ...this.emptyUser },
			userList: userList,
		});
	}

	showDialogEditUser(rowData) {
		this.setState({
			user: rowData,
			flag_DialogEditUser: true,
		});
	}

	hideDialogEditUser() {
		this.setState({
			submitted: false,
			flag_DialogEditUser: false,
		});
	}

	handlerEditUser(user) {
		if (this.state.user.username.length && this.state.user.aka.length) {
			let index = this.state.userList.findIndex(
				(element) => element.username === this.state.user.username
			);
			let userList = [...this.state.userList];
			userList[index].username = this.state.user.username;
			userList[index].aka = this.state.user.aka;
			userList[index].password = this.state.user.password;

			this.setState({
				user: { ...this.emptyUser },
				userList: userList,
				flag_DialogEditUser: false,
				submitted: false,
			});

			this.toast.show({
				severity: "success",
				summary: "Successful",
				detail: "User edited",
				life: 3000,
			});
		}

		this.setState({ submitted: true });
	}

	actionBodyTemplate(rowData) {
		return (
			<div align="center">
				<Button
					icon="pi pi-pencil"
					className="p-button-rounded p-button-secondary p-button-outlined p-mr-2"
					onClick={() => this.showDialogEditUser(rowData)}
				/>
				<Button
					icon="pi pi-trash"
					className="p-button-rounded p-button-secondary p-button-outlined"
					onClick={() => this.showDialogDelUser(rowData)}
				/>
			</div>
		);
	}

	timeBodyTemplate(rowData) {
		return (
			<React.Fragment>
				<span className="p-column-title">Created At</span>
				{rowData.time}
			</React.Fragment>
		);
	}

	usernameBodyTemplate(rowData) {
		return (
			<React.Fragment>
				<span className="p-column-title">Username</span>
				{rowData.username}
			</React.Fragment>
		);
	}

	akaBodyTemplate(rowData) {
		return (
			<React.Fragment>
				<span className="p-column-title">A.k.a</span>
				{rowData.aka}
			</React.Fragment>
		);
	}

	roleBodyTemplate(rowData) {
		return (
			<React.Fragment>
				<span className="p-column-title">Role</span>
				{rowData.role}
			</React.Fragment>
		);
	}

	render() {
		const header = (
			<div className="table-header">
				<span></span>
				<span className="p-input-icon-left">
					<i className="pi pi-search" />
					<InputText
						type="search"
						onInput={(e) => this.setState({ globalFilter: e.target.value })}
						placeholder="Search..."
					/>
				</span>
			</div>
		);

		const paginatorLeft = (
			<Button type="button" icon="pi pi-refresh" className="p-button-text" />
		);
		const paginatorRight = (
			<Button type="button" icon="pi pi-cloud" className="p-button-text" />
		);

		const footerDialogDelete = (
			<React.Fragment>
				<Button
					label="No"
					icon="pi pi-times"
					className="p-button-text"
					onClick={this.hideDialogDelUser}
				/>
				<Button
					label="Yes"
					icon="pi pi-check"
					className="p-button-text"
					onClick={this.handlerDelUser}
				/>
			</React.Fragment>
		);

		const footerDialogAdd = (
			<React.Fragment>
				<Button
					label="Cancel"
					icon="pi pi-times"
					className="p-button-text"
					onClick={this.hideDialogAddUser}
				/>
				<Button
					label="Add"
					icon="pi pi-check"
					className="p-button-text"
					onClick={this.handlerAddUser}
				/>
			</React.Fragment>
		);

		const footerDialogEdit = (
			<React.Fragment>
				<Button
					label="Cancel"
					icon="pi pi-times"
					className="p-button-text"
					onClick={this.hideDialogEditUser}
				/>
				<Button
					label="Save"
					icon="pi pi-check"
					className="p-button-text"
					onClick={this.handlerEditUser}
				/>
			</React.Fragment>
		);

		const leftContents = <React.Fragment></React.Fragment>;

		const rightContents = (
			<span className="p-buttonset">
				<Button
					label="Export to CSV"
					className="p-button-raised p-button-success"
					icon="pi pi-cloud-download"
					onClick={this.exportCSV}
				/>
				<Button
					label="Add"
					className="p-button-raised p-button-info"
					icon="pi pi-plus-circle "
					onClick={this.showDialogAddUser}
				/>
			</span>
		);

		return (
			<div className="datatable-crud-demo datatable-responsive-demo">
				<div className="card">
					<Toast ref={(el) => (this.toast = el)} />

					<Dialog
						visible={this.state.flag_DialogAddUser}
						style={{ width: "450px" }}
						header="User Details"
						modal
						className="p-fluid"
						footer={footerDialogAdd}
						onHide={this.hideDialogAddUser}
					>
						<div className="p-field">
							<label htmlFor="username">Username</label>
							<InputText
								id="username"
								value={this.state.user.username}
								onChange={(e) => this.onInputChange(e, "username")}
								required
								autoFocus
								className={`${
									this.state.submitted && !this.state.user.username
										? "p-invalid"
										: ""
								}`}
							/>
							{this.state.submitted && !this.state.user.username && (
								<small className="p-invalid">Username is required.</small>
							)}
						</div>

						<div className="p-field">
							<label htmlFor="aka">As known as</label>
							<InputText
								id="aka"
								value={this.state.user.aka}
								onChange={(e) => this.onInputChange(e, "aka")}
								required
								autoFocus
								className={`${
									this.state.submitted && !this.state.user.aka
										? "p-invalid"
										: ""
								}`}
							/>
							{this.state.submitted && !this.state.user.aka && (
								<small className="p-invalid">A.K.A name is required.</small>
							)}
						</div>

						<div className="p-field">
							<label htmlFor="password">Password</label>
							<InputText
								id="password"
								value={this.state.user.password}
								onChange={(e) => this.onInputChange(e, "password")}
								required
								autoFocus
								className={`${
									this.state.submitted && !this.state.user.password
										? "p-invalid"
										: ""
								}`}
							/>
							{this.state.submitted && !this.state.user.password && (
								<small className="p-invalid">Password is required.</small>
							)}
						</div>
					</Dialog>

					<Dialog
						visible={this.state.flag_DialogEditUser}
						style={{ width: "450px" }}
						header="User Details"
						modal
						className="p-fluid"
						footer={footerDialogEdit}
						onHide={this.hideDialogEditUser}
					>
						<div className="p-field">
							<label htmlFor="username">Username</label>
							<InputText
								id="username"
								value={this.state.user.username}
								disabled
							/>
						</div>
						<div className="p-field">
							<label htmlFor="aka">As known as</label>
							<InputText
								id="aka"
								value={this.state.user.aka}
								onChange={(e) => this.onInputChange(e, "aka")}
								required
								autoFocus
								className={`${
									this.state.submitted && !this.state.user.aka
										? "p-invalid"
										: ""
								}`}
							/>
							{this.state.submitted && !this.state.user.aka && (
								<small className="p-invalid">A.K.A name is required.</small>
							)}
						</div>

						<div className="p-field">
							<label htmlFor="password">Password</label>
							<InputText
								id="password"
								value={this.state.user.password}
								onChange={(e) => this.onInputChange(e, "password")}
								required
								autoFocus
							/>
						</div>
					</Dialog>

					<Toolbar left={leftContents} right={rightContents} />

					<DataTable
						value={this.state.userList}
						ref={(el) => {
							this.dt = el;
						}}
						selection={this.state.selectedList}
						onSelectionChange={(e) => this.setState({ selectedList: e.value })}
						// selectionMode="multiple"
						metaKeySelection={false}
						style={{ marginTop: "1em" }}
						className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive-demo"
						paginator
						paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
						currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
						rows={10}
						rowsPerPageOptions={[10, 20, 50, 100]}
						paginatorLeft={paginatorLeft}
						paginatorRight={paginatorRight}
						globalFilter={this.state.globalFilter}
						header={header}
					>
						<Column
							field="time"
							header="Created At"
							body={this.timeBodyTemplate}
							sortable
						></Column>
						<Column
							field="username"
							header="Username"
							body={this.usernameBodyTemplate}
							sortable
						></Column>
						<Column
							field="aka"
							header="As known as"
							body={this.akaBodyTemplate}
							sortable
						></Column>
						<Column
							field="role"
							header="Role"
							body={this.roleBodyTemplate}
							sortable
						></Column>
						<Column
							header={<div align="center">Actions</div>}
							body={this.actionBodyTemplate}
						></Column>
					</DataTable>

					<Dialog
						visible={this.state.flag_DialogDelUser}
						style={{ width: "450px" }}
						header="Confirm"
						modal
						footer={footerDialogDelete}
						onHide={this.hideDialogDelUser}
					>
						<div className="confirmation-content">
							<i
								className="pi pi-exclamation-triangle p-mr-3"
								style={{ fontSize: "2rem" }}
							/>
							{this.state.user && (
								<span>
									Are you sure you want to delete{" "}
									<b>{this.state.user.username}</b>?
								</span>
							)}
						</div>
					</Dialog>
				</div>
			</div>
		);
	}
}
