import axios from "axios";

import * as Constants from "../constants/constants";

export default class SummaryService {
	jwt = localStorage.getItem(Constants.STORAGE_JWT);

	summarizeToday() {
		return axios({
			method: "get",
			baseURL: Constants.SERVER_HTTP_URL,
			url: "/sentinel/summary/today",
			timeout: 4000,
			headers: {
				Authorization: "Bearer " + this.jwt,
			},
		});
	}
}
