import React, { Component } from "react";

import { BreadCrumb } from "primereact/breadcrumb";

import { Route } from "react-router-dom";

export default class AppBreadCrumb extends Component {
	render() {
		let items = [];

		const home = { icon: "pi pi-home", url: "/" };

		return (
			<div className="layout-breadcrumb">
				<div className="breadcrumb">
					<div className="route-bar-breadcrumb">
						<Route
							path="*"
							render={(props) => {
								let pathList = props.location.pathname.split("/");
								if (pathList.length >= 2 && pathList[1].length !== 0) {
									let path = "";
									items = [];

									for (let i = 1; i < pathList.length; i++) {
										path += `/${pathList[i]}`;
										items = [
											...items,
											{
												label:
													pathList[i].charAt(0).toUpperCase() +
													pathList[i].slice(1),
												url: path,
											},
										];
									}
								}

								return <BreadCrumb model={items} home={home} />;
							}}
						/>
					</div>
				</div>
				<div className="notification">
					{this.props.msgInfo}
				</div>
			</div>
		);
	}
}
