import React, { Component } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";

import ActivityService from "../services/ActivityService";

export default class AppActivityRecord extends Component {
	emptyMonitor = {
		time: null,
		device_imei: "",
		device_name: "",
		device_type: "",
		command_action: "",
		command_target: "",
		command_author: "",
	};

	constructor(props) {
		super(props);

		this.state = {
			activity: { ...this.emptyMonitor },
			activityList: [],
			globalFilter: null,
		};

		this.activityService = new ActivityService();
		this.exportCSV = this.exportCSV.bind(this);

		this.updateActivityList = this.updateActivityList.bind(this);

		this.timeBodyTemplate = this.timeBodyTemplate.bind(this);
		this.imeiDeviceBodyTemplate = this.imeiDeviceBodyTemplate.bind(this);
		this.nameDeviceBodyTemplate = this.nameDeviceBodyTemplate.bind(this);
		this.typeDeviceBodyTemplate = this.typeDeviceBodyTemplate.bind(this);
		this.actionCommandBodyTemplate = this.actionCommandBodyTemplate.bind(this);
		this.targetCommandBodyTemplate = this.targetCommandBodyTemplate.bind(this);
		this.authorCommandBodyTemplate = this.authorCommandBodyTemplate.bind(this);
	}

	componentDidMount() {
		this.updateActivityList();
	}

	async updateActivityList() {
		try {
			let resp = await this.activityService.recordList();
			let map_list = resp.data.map((element) => {
				let map_obj = {};
				let now_gmt7 = new Date(Date.parse(element.time) + 3600 * 7 * 1000);
				map_obj.time = now_gmt7.toISOString();
				map_obj.device_imei = element.device_imei;
				map_obj.device_name = element.device_name;
				map_obj.device_type = element.device_type;
				map_obj.command_action = element.command_action;
				map_obj.command_target = element.command_target;
				map_obj.command_author = element.command_author;
				return map_obj;
			});
			this.setState({ activityList: map_list });
			// console.log(map_list);
		} catch (error) {
			console.log(error.message);
		}
	}

	exportCSV() {
		this.dt.exportCSV();
	}

	timeBodyTemplate(rowData) {
		return (
			<React.Fragment>
				<span className="p-column-title">Created At</span>
				{rowData.time}
			</React.Fragment>
		);
	}

	imeiDeviceBodyTemplate(rowData) {
		return (
			<React.Fragment>
				<span className="p-column-title">IMEI</span>
				{rowData.device_imei}
			</React.Fragment>
		);
	}

	nameDeviceBodyTemplate(rowData) {
		return (
			<React.Fragment>
				<span className="p-column-title">Name</span>
				{rowData.device_name}
			</React.Fragment>
		);
	}

	typeDeviceBodyTemplate(rowData) {
		return (
			<React.Fragment>
				<span className="p-column-title">Type</span>
				{rowData.device_type}
			</React.Fragment>
		);
	}

	actionCommandBodyTemplate(rowData) {
		return (
			<React.Fragment>
				<span className="p-column-title">Action</span>
				<span>
					<font color={rowData.command_action === "OFF" ? "red" : "blue"}>
						<b>{rowData.command_action}</b>
					</font>
				</span>
			</React.Fragment>
		);
	}

	targetCommandBodyTemplate(rowData) {
		return (
			<React.Fragment>
				<span className="p-column-title">Target</span>
				{rowData.command_target}
			</React.Fragment>
		);
	}

	authorCommandBodyTemplate(rowData) {
		return (
			<React.Fragment>
				<span className="p-column-title">Author</span>
				{rowData.command_author}
			</React.Fragment>
		);
	}

	render() {
		const header = (
			<div className="table-header">
				<span></span>
				<span className="p-input-icon-left">
					<i className="pi pi-search" />
					<InputText
						type="search"
						onInput={(e) => this.setState({ globalFilter: e.target.value })}
						placeholder="Search..."
					/>
				</span>
			</div>
		);

		const paginatorLeft = (
			<Button type="button" icon="pi pi-refresh" className="p-button-text" />
		);
		const paginatorRight = (
			<Button type="button" icon="pi pi-cloud" className="p-button-text" />
		);

		const leftContents = <React.Fragment></React.Fragment>;

		const rightContents = (
			<span className="p-buttonset">
				<Button
					label="Export to CSV"
					className="p-button-raised p-button-success"
					icon="pi pi-cloud-download"
					onClick={this.exportCSV}
				/>
			</span>
		);

		return (
			<div className="datatable-crud-demo datatable-responsive-demo">
				<div className="card">
					<Toast ref={(el) => (this.toast = el)} />

					<Toolbar left={leftContents} right={rightContents} />
					<DataTable
						value={this.state.activityList}
						ref={(el) => {
							this.dt = el;
						}}
						// selection={this.state.selectedList}
						// onSelectionChange={(e) =>
						// 	this.setState({ selectedList: e.value })
						// }
						// selectionMode="multiple"
						// metaKeySelection={false}
						style={{ marginTop: "1em" }}
						className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive-demo"
						paginator
						paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
						currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
						rows={12}
						rowsPerPageOptions={[12, 25, 50, 100]}
						paginatorLeft={paginatorLeft}
						paginatorRight={paginatorRight}
						globalFilter={this.state.globalFilter}
						header={header}
					>
						<Column
							field="time"
							header="Created At"
							body={this.timeBodyTemplate}
							sortable
						></Column>
						<Column
							field="device_imei"
							header="IMEI"
							body={this.imeiDeviceBodyTemplate}
							sortable
						></Column>
						<Column
							field="device_name"
							header="Name"
							body={this.nameDeviceBodyTemplate}
							sortable
						></Column>
						<Column
							field="device_type"
							header="Type"
							body={this.typeDeviceBodyTemplate}
							sortable
						></Column>
						<Column
							field="command_target"
							header="Target"
							body={this.targetCommandBodyTemplate}
							sortable
						></Column>
						<Column
							field="command_action"
							header="Action"
							body={this.actionCommandBodyTemplate}
							sortable
						></Column>
						<Column
							field="command_author"
							header="Author"
							body={this.authorCommandBodyTemplate}
							sortable
						></Column>
					</DataTable>
				</div>
			</div>
		);
	}
}
