import React, { Component } from "react";
import { Link } from "react-router-dom";

import { Button } from "primereact/button";

import SummaryService from "../services/SummaryService";

export default class AppDashboard extends Component {
	emptySummary = {
		record: {
			totalCnt: 0,
			todayCnt: 0,
			yesterdayCnt: 0,
			todayWarningCnt: 0,
			yesterdayWarningCnt: 0,
		},
		monitor: {
			totalCnt: 0,
			todayCnt: 0,
			yesterdayCnt: 0,
		},
	};

	constructor(props) {
		super(props);
		this.state = this.emptySummary;

		this.summaryService = new SummaryService();
		this.updateSummary = this.updateSummary.bind(this);
	}

	componentDidMount() {
		this.updateSummary();
	}

	async updateSummary() {
		try {
			let resp = await this.summaryService.summarizeToday();
			let map_obj = {
				record: {
					totalCnt: resp.data.record.totalCnt,
					todayCnt: resp.data.record.todayCnt,
					yesterdayCnt: resp.data.record.yesterdayCnt,
					todayWarningCnt: resp.data.record.todayWarningCnt,
					yesterdayWarningCnt: resp.data.record.yesterdayWarningCnt,
				},
				monitor: {
					totalCnt: resp.data.monitor.totalCnt,
					todayCnt: resp.data.monitor.todayCnt,
					yesterdayCnt: resp.data.monitor.yesterdayCnt,
				},
			};
			this.setState(map_obj);
			// console.log(resp.data);
		} catch (error) {
			console.log(error.message);
		}
	}

	render() {
		return (
			<div className="layout-dashboard">
				<div className="p-grid">
					<div className="p-col-12 p-md-6 p-xl-3">
						<div className="card no-gutter widget-overview-box widget-overview-box-3">
							<div align="center">
								<span className="overview-title" style={{ fontWeight: "bold" }}>
									Bảng Chức Năng
								</span>
							</div>
							<div className="p-grid overview-detail">
								<div className="p-col-6">
									<Link to="/control">
									<Button
										label="Điều Khiển"
										className="p-button-rounded p-button-success p-button-outlined p-button-lg"
									/>
									</Link>
								</div>
								<div className="p-col-6">
									<Link to="/activities">
									<Button
										label="Lịch Sử ..."
										className="p-button-rounded p-button-info p-button-outlined p-button-lg"
									/>
									</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="p-col-12 p-md-6 p-xl-3">
						<div className="card no-gutter widget-overview-box widget-overview-box-1">
							<span className="overview-icon">
								<i className="pi pi-th-large"></i>
							</span>
							<span className="overview-title">Thống Kê</span>
							<div className="p-grid overview-detail">
								<div className="p-col-6">
									<div className="overview-number">
										{this.state.record.todayCnt}
									</div>
									<div className="overview-subtext">điều khiển hôm nay</div>
								</div>
								<div className="p-col-6">
									<div className="overview-number">
										{this.state.record.yesterdayCnt}
									</div>
									<div className="overview-subtext">điều khiển hôm qua</div>
								</div>
							</div>
						</div>
					</div>

					<div className="p-col-12 p-md-6 p-xl-3">
						<div className="card no-gutter widget-overview-box widget-overview-box-2">
							<span className="overview-icon">
								<i className="pi pi-filter"></i>
							</span>
							<span className="overview-title">Bơm Nước</span>
							<div className="p-grid overview-detail">
								<div className="p-col-6">
									<div className="overview-number">
										{this.state.record.todayWarningCnt}
									</div>
									<div className="overview-subtext">điều khiển hôm nay</div>
								</div>
								<div className="p-col-6">
									<div className="overview-number">
										{this.state.record.yesterdayWarningCnt}
									</div>
									<div className="overview-subtext">điều khiển hôm qua</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
