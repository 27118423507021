export const SERVER_HTTP_URL = "https://iot.vovan.cc/api";
export const SERVER_WS_URL = "wss://iot.vovan.cc/ws";
export const STORAGE_JWT = "JWT";
export const STORAGE_JWT_EXPIRATION = "jwtExpiration";
export const STORAGE_USER_NAME = "userName";
export const STORAGE_USER_ROLE = "userRole";
export const STORAGE_USER_AKA = "userAka";

export const PUBLISH_KEY = "2c42cd91b5a7a2abb8462f659c699751";
export const IMEI_HELLO = "B8:F0:09:B3:02:98";
// export const IMEI_HELLO = "B8:F0:09:B5:5E:40";
