import React, { Component } from "react";
import { Panel } from "primereact/panel";

import LogService from "../services/LogService";

export default class AppLog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: "",
		};

		this.logService = new LogService();
		this.updateLog = this.updateLog.bind(this);
	}

	componentDidMount() {
		this.updateLog();
	}

	async updateLog() {
		try {
			let resp = await this.logService.logPowerUp();
			this.setState({ data: resp.data });
			// console.log(resp.data);
		} catch (error) {
			console.log(error.message);
		}
	}

	render() {
		return (
			<Panel header="Logs" toggleable>
				<div
					style={{
						lineHeight: "1.5",
						whiteSpace: "pre-wrap",
					}}
				>
					<a href="http://log.vovan.cc/hiot_dev/">http://log.vovan.cc/hiot_dev/</a>
				</div>
			</Panel>
		);
	}
}
