import React, { Component } from "react";

export default class AppClock extends Component {
	timerID = undefined;

	constructor(props) {
		super(props);

		this.state = { date: new Date() };

		this.tick = this.tick.bind(this);
	}

	tick() {
		this.setState({
			date: new Date(),
		});
	}

	componentDidMount() {
		this.timerID = setInterval(() => this.tick(), 1000);
	}

	componentWillUnmount() {
		clearInterval(this.timerID);
	}

	render() {
		return (
			<div align="center">
				<h6>
					It is {this.state.date.toLocaleTimeString()} {this.props.gmt}.
				</h6>
			</div>
		);
	}
}

AppClock.defaultProps = { gmt: "GMT+7" };
